@font-face {
	font-family: 'Neue Plak';
	src: url(../fonts/Neue-Plak-Regular.otf);
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Plak';
	src: url(../fonts/Neue-Plak-SemiBold.otf);
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Plak';
	src: url(../fonts/Neue-Plak-Bold.otf);
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Plak';
	src: url(../fonts/Neue-Plak-Black.otf);
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url(../fonts/Rubik-Regular.ttf);
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url(../fonts/Rubik-Medium.ttf);
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url(../fonts/Rubik-SemiBold.ttf);
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url(../fonts/Rubik-Bold.ttf);
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url(../fonts/Rubik-Black.ttf);
	font-weight: 800;
	font-style: normal;
}
